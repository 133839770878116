
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import {
    getInvoiceInfo,
    getApplyInvoice,
    GetInvoiceInfoDto
} from '@/api/invoice'

export default defineComponent({
    name: 'ApplyInvoice',
    components: {
        'page-head': pageHead
    },
    data () {
        return {
            invoiceInfo: {} as GetInvoiceInfoDto,
            email: '',
            phone: ''
        }
    },
    created () {
        this.getInvoiceInfo()
    },
    methods: {
        async getInvoiceInfo () {
            const result = await getInvoiceInfo()
            this.invoiceInfo = result
            console.log(this.invoiceInfo, 'result')
        },
        async onApplyInvoice () {
            if (!this.email) {
                this.$message.warning('请输入邮箱')
                return
            }
            if (!this.phone) {
                this.$message.warning('请输入手机号')
                return
            }
            await this.$util.confirm('申请发票', '确认申请发票')
            await getApplyInvoice({
                email: this.email,
                mobile: this.phone
            })
            this.$message.success('申请成功')
        }
    }
})
